import React, { FC, useEffect, useState } from "react";
import "./App.css";

type Corpus = Map<string, string[]>;

const backronymsToGenerate = 3;
const range = Array.from(
  { length: backronymsToGenerate },
  (_, index) => index + 1
);

const corpusFilenames = [
  {
    label: "DeReKo-2014",
    filename: "DeReKo-2014-II-MainArchive-STT.100000.freq",
    parser: extractTsvWords,
  },
  {
    label: "derewo-2012",
    filename: "derewo-v-ww-bll-320000g-2012-12-31-1.0.txt",
    parser: extractTsvWords,
  },
  {
    label: "Aspell DE Dictionary (LARGE FILE!)",
    filename: "german.dic",
    parser: extractDictionary,
  },
];

export const App: FC = () => {
  const [selectedCorpuses, setSelectedCorpuses] = useState(getCorpusStateMap());
  const [corpus, setCorpus] = useState<Corpus>(new Map());
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const wordlists = await Promise.all(
        [...selectedCorpuses.entries()]
          .filter(([, selected]) => selected)
          .map(([filename]) => {
            const cf = corpusFilenames.find((c) => c.filename === filename);
            return fetchCorpusFile(filename, cf!.parser);
          })
      );
      const wordlist = mergeCorpuses(wordlists);
      setCorpus(wordlist);
      setIsLoading(false);
    })();
  }, [selectedCorpuses]);

  return (
    <div className="App">
      <h1>JETI Name Generator</h1>
      <CorpusSelector setSelectedCorpuses={setSelectedCorpuses} />
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <BackronymGenerator corpus={corpus} />
      )}
    </div>
  );
};

const BackronymGenerator = ({ corpus }: { corpus: Corpus }) => {
  const [acronym, setAcronym] = useState("JETI");
  const [, setReloader] = useState(0);
  return (
    <>
      <div>
        <input
          type="text"
          value={acronym}
          onChange={(event) => setAcronym(event.target.value)}
        />
        <button onClick={() => setReloader((r) => r + 1)}>Try again</button>
      </div>
      {range.map((_, idx) => {
        const b = generateBackronym(corpus, acronym);
        return (
          <div className="backronym" key={idx}>
            {b.map((w, idx2) => (
              <span className="word" key={idx2}>
                {w}
              </span>
            ))}
          </div>
        );
      })}
    </>
  );
};

const CorpusSelector = ({
  setSelectedCorpuses,
}: {
  setSelectedCorpuses: (m: Map<string, boolean>) => void;
}) => {
  const [selected, setSelected] = useState(getCorpusStateMap());
  return (
    <div className="corpus-selector">
      <span>Corpus files:</span>
      {corpusFilenames.map(({ label, filename }) => (
        <span key={filename}>
          <input
            type="checkbox"
            id={filename}
            name={filename}
            defaultChecked={selected.get(filename)}
            onChange={(e) =>
              setSelected((s) => s.set(filename, e.target.checked))
            }
          />
          <label htmlFor={filename}>{label}</label>
        </span>
      ))}
      <span>
        <button
          onClick={() => {
            setSelectedCorpuses(new Map(selected));
          }}
        >
          Apply
        </button>
      </span>
    </div>
  );
};

function getCorpusStateMap(): Map<string, boolean> {
  const m = new Map(corpusFilenames.map(({ filename }) => [filename, false]));
  m.set(corpusFilenames[0].filename, true);
  return m;
}

function generateBackronym(words: Corpus, acronym: string): string[] {
  return acronym
    .toLowerCase()
    .split("")
    .map((letter) => {
      const wordsStartingWithLetter = words.get(letter) ?? [""];
      return wordsStartingWithLetter[randomInt(wordsStartingWithLetter.length)];
    });
}

function randomInt(max: number): number {
  return Math.floor(Math.random() * Math.floor(max));
}

async function fetchCorpusFile(
  url: string,
  parser: (text: string) => Corpus
): Promise<Corpus> {
  const response = await fetch(url, {
    headers: { "Accept-Encoding": "gzip" },
  });
  const text = await response.text();
  return extractTsvWords(text);
}

function extractTsvWords(wordsText: string): Corpus {
  const wordList = wordsText
    .split("\n")
    .map((line) => line.trim().split(new RegExp("\\s"))[0].trim());
  return buildCorpus(wordList);
}

function extractDictionary(wordsText: string): Corpus {
  const wordList = wordsText.split("\n").map((line) => line.trim());
  return buildCorpus(wordList);
}

function buildCorpus(wordList: string[]): Corpus {
  const corpus = new Map<string, string[]>();
  for (const word of wordList) {
    if (!word) continue;
    const letter = word.split("")[0].toLowerCase();
    if (!corpus.has(letter)) corpus.set(letter, []);
    corpus.get(letter)?.push(word);
  }
  return corpus;
}

function mergeCorpuses(corpuses: Corpus[]): Corpus {
  const mergedCorpus = new Map<string, string[]>();
  for (const corpus of corpuses) {
    for (const [letter, words] of corpus.entries()) {
      mergedCorpus.set(letter, (mergedCorpus.get(letter) ?? []).concat(words));
    }
  }
  return mergedCorpus;
}
